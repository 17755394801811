<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Nuevo Post</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Title -->
        <div class="mt-2">
          <vs-input @blur="post.title= trimString(post.title)" label="Titulo" v-model="post.title"
                    class="w-full" name="title"
                    v-validate="'required|min:20|max:80'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('title')">{{ errors.first('title') }}</span>
        </div>
        <!--End-->
        <!--Date-->
        <div class="mt-2">
          <label class="text-sm opacity-75">Fecha de publicación</label>
          <flat-pickr :config="configdateTimePicker" v-model="post.publicationDate"
                      class="w-full" name="publicationDate" v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('publicationDate')">{{ errors.first('publicationDate') }}</span>
        </div>
        <!--End-->
        <!-- -Order -->
        <div class="mt-2">
          <div>
            <vs-input type="number" label="Orden"
                      v-model.number="post.position"
                      class="w-full" name="position" v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('position')">{{ errors.first('position') }}</span>
          </div>
        </div>
        <!--End-->
        <!--Resumen-->
        <div class="mt-5">
          <vs-textarea label="Resumen"
                       v-model="post.microDescription" class="w-full"
                       name="resumen"/>
          <span class="text-danger text-sm"
                v-show="errors.has('resumen')">{{ errors.first('resumen') }}</span>
        </div>
        <!--End-->
        <!--Description-->
        <div class="mt-5">
          <vs-textarea label="Descripción"
                       v-model="post.description" class="w-full"
                       name="description" rows="15"/>
          <span class="text-danger text-sm"
                v-show="errors.has('description')">{{ errors.first('description') }}</span>
        </div>
        <!--End-->
        <!--Image-->
        <div class="mt-5">
          <template v-if="post.image">
            <!-- Image Container -->
            <div class="img-container flex items-center justify-center">
              <img :src="post.image" alt="img" class="responsive">
            </div>
            <!-- Image upload Buttons -->
            <div class="modify-img flex justify-between mt-5">
              <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg"
                     accept="image/*">
              <span style="cursor: pointer" class="ml-2" type="flat"
                    @click="$refs.updateImgInput.click()">Cambiar
                                </span>
              <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                    @click="deleteImage">Eliminar</span>
            </div>
          </template>
        </div>
        <div class=" mt-5">
          <!-- Upload image  -->
          <div class="upload-img" v-if="!post.image">
            <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg"
                   accept="image/*">
            <vs-button @click="$refs.uploadImgInput.click()">Imagen (1280 x 720)</vs-button>
          </div>
        </div>
        <!--End image-->
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from "../../mixins/trimString"
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

const firebase = require("firebase")
// Required for side-effects
require("firebase/firestore")
let db = firebase.firestore()
export default {
  name: 'NewPost',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    flatPickr
  },
  mixins: [trimString],
  data() {
    return {
      post: {},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      newImage: null,
      progress: false,
      configdateTimePicker: {
        enableTime: false,
        dateFormat: 'Y-m-d H:i'
      }
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return
      this.post = {
        image: null
      }
      this.$validator.reset()
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any()
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    submitData() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.progress = true
          if (this.newImage != null) {
            this.post.image = await this.submitImages(this.newImage)
          }
          this.post.slug = this.getSlug(this.post.title)
          this.post.publicationDate = new Date(this.post.publicationDate)
          db.collection('posts').add({
            ...this.post,
            uid: firebase.auth().currentUser.uid,
            state: false,
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
          }).then((doc) => {
            let obj = {
              id: doc.id,
              ...this.post,
              createdAt: new Date(),
              state: false
            }
            obj.publicationDate = new Date(this.post.publicationDate)
            this.$store.dispatch('posts/addPost', {post: obj})
            this.progress = false
            this.$vs.notify({
              color: 'success',
              title: 'Post',
              text: 'Post creado correctamente.'
            })
            this.$emit('closeSidebar')
          }).catch(() => {
            this.progress = false
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          })
        }
      })
    },
    submitImages(image) {
      return new Promise((resolve, reject) => {
        let imageName = Math.random().toString(36).substr(1, 20)
        let fileExtension = '.' + image.name.split('.').pop()
        let imageNameFinal = imageName + fileExtension

        let storageRef = firebase.storage().ref(`posts/${imageNameFinal}`)
        storageRef.put(image)
            .then((snapshot) => {
              snapshot.ref.getDownloadURL().then((url) => {
                resolve(url)
              })
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.post.image = e.target.result
          this.newImage = input.target.files[0]
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    deleteImage() {
      this.post.image = null
      this.newImage = null
    },
    getSlug(name) {
      let text = (name).trim().split(' ').join('-').toLowerCase().normalize('NFD')
          .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
          .normalize();
      return text
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 850px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
